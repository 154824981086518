import { apiService } from "./base";

export const roleApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    // getProcess: build.query<Response<any>, undefined>({
    //   query: ({any}) => ({
    //     url: "v1.0/processes",
    //     method: "GET",
    //   }),
    // }),

    createLearnLessonHistory: build.mutation<
      Response<any>,
      { lesson_id: number }
    >({
      query: ({ lesson_id }) => ({
        url: `/v1.0/lesson-learning-history/crud/me/create`,
        method: "POST",
        body: { lesson_id },
      }),
    }),

    createLogProgress: build.mutation<
        Response<any>,
        Body
    >({
      query: (Body) => ({
        url: `/v1.0/progress`,
        method: "POST",
        body :Body
      }),
    }),

    createLogProgressLesson: build.mutation<
        Response<any>,
        Body
    >({
      query: (Body) => ({
        url: `/v1.0/progress/lession-progress/create`,
        method: "POST",
        body :Body
      }),
    }),

    createLogProgressLessonItem: build.mutation<
        Response<any>,
        Body
    >({
      query: (Body) => ({
        url: `/v1.0/progress/lession-progress-log/create`,
        method: "POST",
        body :Body
      }),
    }),

    getMyProcess: build.query<any, any>({
      query: (params) => ({
        url: `/v1.0/progress/report-general`,
        method: "GET",
        params,
      }),
    }),

    getCurrentLessonProcess: build.query<any, any>({
      query: (params) => ({
        url: `/v1.0/progress/questions-answered`,
        method: "GET",
        params,
      }),
    }),

  }),
});

export const {
    // useGetProcessQuery,
    useCreateLogProgressMutation,
  useCreateLearnLessonHistoryMutation,
    useGetCurrentLessonProcessQuery,
    useGetMyProcessQuery,
    useCreateLogProgressLessonItemMutation,
    useCreateLogProgressLessonMutation

} = roleApi;
