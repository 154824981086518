import { apiService } from "./base";

export const NavigationApi = apiService.injectEndpoints({
  endpoints: (build) => ({
    listNavigation: build.query<any[], any>({
      query: (params) => ({
        url: `/v1.0/cms/navigation`,
        method: "GET",
        params,
      }),
    }),
    getNavigation: build.query<Response<any>, { _id: string }>({
      query: ({ _id }) => ({
        url: `/v1.0/cms/navigation/${_id}`,
        method: "GET",
      }),
    }),
    deleteNavigation: build.mutation<Response<any>, { _id: string }>({
      query: ({ _id }) => ({
        url: `/v1.0/cms/navigation/${_id}`,
        method: "DELETE",
      }),
    }),
    createNavigation: build.mutation<Response<{ _id: string }>, any>({
      query: (body) => ({
        url: `/v1.0/cms/navigation`,
        method: "POST",
        body,
      }),
    }),
    updateNavigation: build.mutation<
      Response<{ id: number }>,
      { body: any; _id: string }
    >({
      query: ({ body, _id }) => ({
        url: `/v1.0/cms/navigation/${_id}`,
        method: "PUT",
        body: { ...body, content: JSON.stringify(body.richContent) },
      }),
    }),
  }),
});

export const {
  useListNavigationQuery,
  useGetNavigationQuery,
  useCreateNavigationMutation,
  useUpdateNavigationMutation,
  useDeleteNavigationMutation,
} = NavigationApi;
