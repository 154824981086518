import { FunctionComponent, lazy, LazyExoticComponent } from "react";

const SignInView = lazy(() => import("@views/auth-views/sign-in"));
const SignUpView = lazy(() => import("@views/auth-views/sign-up"));
const ActiveView = lazy(() => import("@views/auth-views/active"));
const HomePage = lazy(() => import("@views/app-views/home"));
const CoursesView = lazy(() => import("@views/app-views/courses"));
const LiveClassView = lazy(() => import("@views/app-views/live-class"));
const ProfilePageView = lazy(() => import("@views/app-views/profile"));
const LearningReportView = lazy(
  () => import("@views/app-views/learning-report")
);
const InstructionsView = lazy(
    () => import("@views/app-views/instructions")
);

const ExamSchoolView = lazy(() => import("@views/app-views/exam-school"));
const ExamView = lazy(() => import("@views/app-views/exam"));
const CourseDetailView = lazy(() => import("@views/app-views/course-detail"));
const LessonVideo = lazy(() => import("@views/app-views/lesson"));
const ForgotPasswordView = lazy(
  () => import("@views/auth-views/forgot-password")
);
const AllCourse = lazy(() => import("@views/app-views/all-course/AllCourse"));
const Article = lazy(() => import("@views/app-views/article"));
const Khoahoctrungbay = lazy(() => import("@views/app-views/khoahoctrungbay"));

interface Route {
  key: string;
  path: string;
  component: LazyExoticComponent<FunctionComponent | React.FC>;
  public: boolean;
  ready: boolean;
  nav: boolean;
}

export const routes: Route[] = [
  {
    key: "sign-in",
    path: "auth/sign-in",
    component: SignInView,
    public: true,
    ready: true,
    nav: true,
  },

  {
    key: "instructions",
    path: "/instructions",
    component: InstructionsView,
    public: true,
    ready: true,
    nav: true,
  },

  {
    key: "khoahoctrungbay",
    path: "/displaycourse",
    component: Khoahoctrungbay,
    public: true,
    ready: true,
    nav: true,
  },

  {
    key: "instructions-detail",
    path: "/instructions/:id",
    component: Article,
    public: false,
    ready: true,
    nav: true,
  },
  {
    key: "sign-up",
    path: "auth/sign-up",
    component: SignUpView,
    public: true,
    ready: true,
    nav: true,
  },
  {
    key: "forgot-password",
    path: "auth/forgot-password",
    component: ForgotPasswordView,
    public: true,
    ready: true,
    nav: true,
  },
  {
    key: "sign-up",
    path: "auth/active",
    component: ActiveView,
    public: true,
    ready: true,
    nav: true,
  },
  // Home page
  {
    key: "home",
    path: "",
    component: HomePage,
    public: true,
    ready: true,
    nav: true,
  },
  // Courses page
  {
    key: "courses",
    path: "/courses",
    component: CoursesView,
    public: true,
    ready: true,
    nav: true,
  },
  // Live class
  {
    key: "live-class",
    path: "/live-class",
    component: LiveClassView,
    public: false,
    ready: true,
    nav: true,
  },
  // all course
  {
    key: "all-course",
    path: "/all-course",
    component: AllCourse,
    public: true,
    ready: true,
    nav: true,
  },
  // Profile
  {
    key: "profile",
    path: "/profile",
    component: ProfilePageView,
    public: false,
    ready: true,
    nav: true,
  },
  // Learning report
  {
    key: "learning-report",
    path: "/learning-report",
    component: LearningReportView,
    public: false,
    ready: true,
    nav: true,
  },
  // Learning
  {
    key: "course-detail",
    path: "/course/:id",
    component: CourseDetailView,
    public: true,
    ready: true,
    nav: true,
  },
  // Exam school
  {
    key: "exam-school",
    path: "/exam-school",
    component: ExamSchoolView,
    public: false,
    ready: true,
    nav: true,
  },
  {
    key: "exam",
    path: "/exam/:id",
    component: ExamView,
    public: true,
    ready: true,
    nav: true,
  },
  // Lesson
  {
    key: "lesson-video",
    // path: "/lesson/:id/:path",
    path: "/lesson/:id",
    component: LessonVideo,
    public: true,
    ready: true,
    nav: true,
  },
];

export const publicRoute = routes.filter((route) => route.public);
export const privateRoute = routes.filter((route) => !route.public);

export const sideNavigation = routes.filter((route) => route.nav);
